import { graphql } from 'gatsby'
import { map } from 'rambdax'
import PageLayout from '../components/PageLayout/PageLayout'
import ZoneInlineWaitlist, {
  ZoneInlineWaitlistProps,
} from '../zones/InlineWaitlist/InlineWaitlist'
import styles from './corporateFounders.module.scss'
import { Container } from '@algbra/ui/components/Grid/Grid'
import SubpageHeader from '../components/SubpageHeader/SubpageHeader'
import { getMetaTags } from '@algbra/utils/metaTags'
import ZoneFounderList, {
  ZoneFounderListProps,
} from '../zones/FounderList/FounderList'

type ZoneItemType = ZoneInlineWaitlistProps | ZoneFounderListProps

export default function LayoutGeneral({ pageContext, data, location }) {
  const zones: ZoneItemType[] = data.strapiGQL.corporatePage.contentZones

  return (
    <PageLayout theme="corporateFounders">
      <SubpageHeader
        title={pageContext.title}
        summary={pageContext.subtitle}
        metaTags={getMetaTags(pageContext)}
      />

      {map(zone => {
        switch (zone.__typename) {
          case 'StrapiGQL_ComponentZonesInlineWaitlist':
            return <ZoneInlineWaitlist key={zone.id} {...zone} theme="dark" />
          case 'StrapiGQL_ComponentZonesFounderList':
            return <ZoneFounderList key={zone.id} {...zone} />
          default:
            return null
        }
      }, zones)}
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query ($id: ID!) {
    strapiGQL {
      corporatePage(id: $id) {
        ...StrapiGQL_CorporatePagesWithContentZones
      }
    }
  }
`
