import Button from '@algbra/ui/components/Button/Button'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { getIconByName } from '@algbra/ui/icons'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styles from './InlineWaitlist.module.scss'

export type ZoneInlineWaitlistProps = {
  __typename: 'StrapiGQL_ComponentZonesInlineWaitlist'
  title: string
  theme?: 'dark'
}

export const QUERY_CORPORATE_OUTRO_BANNER = graphql`
  query {
    strapiGQL {
      corporateOutroBanner {
        title
        subtitle
        id
      }
    }
  }
`
export default function ZoneInlineWaitlist(
  props: ZoneInlineWaitlistProps
): JSX.Element {
  const {
    strapiGQL: { corporateOutroBanner },
  } = useStaticQuery(QUERY_CORPORATE_OUTRO_BANNER)
  const { title, subtitle } = corporateOutroBanner

  return (
    <Whitespace type="md" className={styles.whitespace} hasAnimation={false}>
      <div
        className={classNames(
          styles.container,
          styles[`theme__${props.theme}`]
        )}
        id="earlyaccess"
      >
        <Container fluid={false} className={styles.contentContainer}>
          <div className={styles.card}>
            <StaticImage
              src="../../images/outroBanner-3.png"
              alt="Algbra Card"
              layout="constrained"
              width={517}
              height={377}
              quality={100}
              placeholder="blurred"
            />
          </div>
          <div className={styles.frame}>
            <Heading level={2} type="xl" className={styles.title}>
              {title}
            </Heading>
            {subtitle && <p className={styles.text}>{subtitle}</p>}

            <Button
              size="small"
              theme={props.theme === 'dark' ? 'light' : 'primary'}
              icon={getIconByName('arrow-right')}
              onClick={() => {
                window.open('/reports/annual-reports', '_self')
              }}
            >
              Find out more
            </Button>
          </div>
        </Container>
      </div>
    </Whitespace>
  )
}
