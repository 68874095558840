import { resolveAsImage } from '@algbra/ui/resolvers'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import styles from './FounderList.module.scss'
import { Container } from '@algbra/ui/components/Grid/Grid'

export type ZoneFounderListProps = {
  __typename: 'StrapiGQL_ComponentZonesFounderList'
  Founder: Array<{
    id: string
    name: string
    bio: string
    title: string
    image: GatsbyResolvedAsset
  }>
}
export default function ZoneFounderList(props: ZoneFounderListProps) {
  return (
    <Container>
      {props.Founder.map((founder, index) => {
        return (
          <div
            className={classNames(
              styles.container,
              index % 2 === 1 && styles.reverse
            )}
          >
            <div className={styles.imageContainer}>
              <GatsbyImage
                image={resolveAsImage(founder.image)}
                alt={founder.name}
                loading="lazy"
              />
            </div>
            <div className={styles.textContainer}>
              <h3 className={styles.header}>{founder.name}</h3>
              <div className={styles.subHeader}>{founder.title}</div>
              <div
                className={styles.bio}
                dangerouslySetInnerHTML={{ __html: founder.bio }}
              ></div>
            </div>
          </div>
        )
      })}
    </Container>
  )
}
