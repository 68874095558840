import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { ThemeContext } from '@algbra/ui/enums/theme'
import classNames from 'classnames'
import { ReactNode, useContext } from 'react'
import { PageMetaTags } from '@algbra/utils/metaTags'
import MetaTags from '../MetaTags/MetaTags'
import styles from './SubpageHeader.module.scss'

export type SubpageHeaderProps = {
  title: ReactNode
  summary?: string
  metaTags: PageMetaTags
  before?: ReactNode
  after?: ReactNode
}

export default function SubpageHeader({
  title,
  metaTags,
  summary,
  before,
  after,
}: SubpageHeaderProps) {
  const { theme } = useContext(ThemeContext)
  return (
    <Container role="complementary">
      <MetaTags {...metaTags} />
      <Whitespace
        as="header"
        type="xl"
        className={classNames(styles.header)}
        hasAnimation={false}
      >
        {before}
        <Heading level={1} type="xl" className={styles.title} theme={theme}>
          {title}
        </Heading>
        {summary && (
          <FreeText className={styles.summary} innerHTML={summary}>
            {summary}
          </FreeText>
        )}
        {after}
      </Whitespace>
    </Container>
  )
}
